/* como não temos outros menus ainda, eu removi o .navigationBtn:hover,.navigationBtn:focus, deixando apenas o active. Quando criar outras páginas, voltar as duas pseudo classes */
ol, li {
    list-style-type: none;
    padding: 0;
}

li {
    display: flex;
    flex-direction: row;
    padding: 5px 0 5px 0;
}

aside {
    width: 15.9rem;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
    direction: row;
    padding-right: 16px;
    background-color: white;
    height: 100vh;
}

.container {
    padding: 16px;
    left: 0px;
    top: 0px;
    font-family:"Plus Jakarta Sans";
    display: flex;
    flex-direction: column;
}

.containerOrganization{
    font-size: 20px;
    color: #757383;
    text-align: center;
    margin-bottom: 32px;
}

.header{
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid #CFCFDB;
    padding: 8px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    margin-bottom: 32px;
}

.headerName {
    color: #4E4E65;
    font-size: 12px;
    border: none;
    outline: none;
    font-weight: 700;
    width: 100%;
}

.headerName:hover{
    background-color: #c9c6dc1e;
}

.headerEmail {
    color:#4E4E65;
}

.menu{
    display: flex;
    flex-direction: column;
    padding: 0;
}

.menuItem {
    display: flex;
    flex-direction: row;
    padding: 16px;
    border-radius: 5px;
}

.navigationBtn {
    width: 220px;
    display: flex;
    color: #757383 !important;
    justify-content: flex-start !important;
}

.navigationBtn p {
    font-weight: 700 !important;
    font-size: 16px !important;
    text-transform:none !important;
    line-height: 160% !important;
}

.active{
   background-color:#757383 !important;
}

.active button{
    color:#fff !important;
}

.footer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: auto;
}


.footer p{
    color: #493D8A;
    font-size: 12px;
}


.containerLogo{
    max-width: 88px;
}

.navigationBtn span {
    margin: 0;
    padding-right: 33px;
}

.menu-icon{
    position: absolute;
    top: 12px;
    left: 12px;
    font-size: 32px !important;
}

.menu-overlay{
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
}

.menu-overlay.isOpen{
    display: unset;
}

@media (max-width: 1024px){
    aside{
        position: absolute;
        left: -20rem;
        transition: ease-in-out 0.5s all;
    }

    aside.isOpen{
        left: 0;
        height: 90vh;
    }

    .container{
        z-index: 1;
        background-color: white;
    }
}
