.personalInfoContainer{
    display: flex;
    flex-direction: row;
}

.avatarContainer {
    margin: 10px;
    background-color: unset !important;
    border: none !important;
    cursor: pointer;
    position: relative;
}

.iconCamera{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none !important;
}

.avatarContainer:hover .iconCamera{
    display: block !important;
}

.avatarContainer:hover .iconAvatar{
    opacity: 0.5;
}

.colaboratorInfo {
    padding: 0 8px !important;
    border-radius: 5px !important;
}


.colaboratorInfo input{
    padding: 0 !important;
}

.colaboratorInfo input:hover{
    background-color: #c9c6dc1e !important;
    text-decoration: none !important;
}

.colaboratorDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}

.colaboratorDetailsRow{
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.colaboratorDetailsRow.marginTop{
    margin-top: 12px;
}

.personalInfoDetails {
    font-family: 'Plus Jakarta Sans';
    padding: 24px;
    min-width: 300px;
}

.personalInfoDetails p{
    margin-bottom: 5px;
}

.personalInfoDetails:hover .personalInfoDetails:hover input{
    background: #c9c6dc1e !important;
    cursor: pointer;
}

.personalInfoDetails:hover input {
    text-decoration: underline;
}

.skillSetSelect{
    height: 32px !important;
    padding: 16.5px 14px 16.5px 16.5px !important; 
    color: #493D8A !important;
    font-weight: bold !important;
}

.skillSetSelect svg {
    right: unset !important;
    left: 7px !important;
    fill: #493D8A !important;
}

.skillSetSelect fieldset{
    border: none !important;
}

.colaboratorDetailsTeams{
    display: flex;
    gap: 8px
}


@media (max-width: 1024px){
    .ledInfos{
        padding: 0;
        margin-top: 24px;
    }

    .personalInfoContainer{
        width: 100%;
        flex-direction: column;
    }

    .avatarContainer{
        margin: 0;
    }
    
    .avatarContainer > div{
        margin: 0 auto;
    }
    
    .colaboratorDetails{
        flex-direction: column;
        flex-wrap: wrap; 
        justify-content: space-between;
    }

    .colaboratorInfo{
        padding: 6px 0;
    }

    .colaboratorInfo > div{
        font-size: 12px !important;
        text-align: center;
        margin: 0 auto;
    }

    .colaboratorDetails > .colaboratorInfo:nth-child(1){
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .colaboratorDetails > .colaboratorInfo:nth-child(1) div{
        margin-top: 18px;
        width: unset !important;
    }

    .colaboratorDetails > .colaboratorInfo:nth-child(1) div input{
        font-size: 32px;
        text-align: center;
    }

    .colaboratorDetailsRow{
        justify-content: space-around;
    }

    .colaboratorDetailsRow:nth-child(1) > .colaboratorInfo{
        padding: 0 !important;
    }

    .colaboratorDetailsRow:nth-child(3){
        align-items: flex-start;
        flex-direction: column !important;
    }

    .colaboratorDetailsTeams{
        margin-top: 30px;
    }
}