nav ol li {
    padding-left: 10px;
}
.breadcrumb {
    padding: 35px 40px 16px 53px;
    height: fit-content;
    width: auto;
}
.ancestor {
    color: #F1388D !important;
}

.actual {
    color:#757383 !important;
}
.ancestor, .actual {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

@media (max-width: 1024px){
    .breadcrumb {
        padding: 48px 24px 0 24px;
    }

    .ancestor, .actual {
        font-size: 12px !important;
    }
}
