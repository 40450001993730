.okrsBoard {
    margin: 0 52px 40px;
    padding: 24px;
    border: 1px solid #C9C6DC;
    border-radius: 6px;
    height: fit-content;
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.addOkrs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px; 
    border-bottom: 1px solid #CFCFDB;
}


.okrsContainer {
    margin-top: 8px;
}

.okrContainer{
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
}

.okrContainer:last-child{
    margin-bottom: 0;
}

.okrContainerHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    border-bottom: 1px solid #CFCFDB;
}

.okrContainerHeaderInput {    
    font-weight: 700 !important;
    border: 0;
    font-size: 32px !important;
    color: #493D8A !important;
}

.keyResultsContainer{
    margin-bottom: 24px;
}

.keyResultsContainer:last-child{
    margin-bottom: 0;
}

.keyResultItem{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #F5F7FF;
    margin-bottom: 12px;
    align-items: center;
    padding-bottom: 12px;
    gap: 8px;
}

.keyResultItemLeft{
    flex: 0 0 85%;
    display: flex;
    flex-direction: column;
}

.keyResultItemLeftTitle{
    font-size: 18px !important;
    color: #493D8A !important;
    margin: 0 !important;
    padding: 0 2px;
    border-radius: 5px;
    font-weight: bold !important;
}

.keyResultItemLeftTitle:hover, .keyResultItemLeftDescription:hover{
    background-color: #c9c6dc1e;
}

.keyResultItemLeftDescription{
    margin: 0;
    font-size: 14px !important;
    color: #493D8A !important;
    font-weight: 300 !important;
    padding: 0 2px;
    border-radius: 5px;
}

.keyResultItemMiddle{
    display: flex;
    flex-direction: column;
    flex: 0 0 10%;
}

.keyResultItemMiddleLabel{
    font-size: 12px;
    color: #665Daa;   
}

.keyResultItemMiddleAtg{
    margin: 0;
    font-size: 16px !important;
    color: #F3328D !important;
    font-weight: bold !important;
}


.keyResultItemRight > button, .deleteOkrBtn{
    flex: 0 0 5%;
    color: #f1388D !important;
    min-width: unset !important;
}

.addOkrBtn {
    display: inline-flex;
    justify-content: flex-start !important;
    color:#F1388D !important;
    border-color: #FBC4DD !important;
    color:#F1388D !important;
    text-transform:none !important;
    font-size: 10px !important;
    font-family:"Plus Jakarta Sans" !important;
}


.addKeyResultBtnContainer{
    display: flex;
}

.addKeyResultBtn {
    display: flex;
    color:#493D8A;
    font-size: 16px;
    padding: 16px;
    border: 1px solid #493D8A;
    border-style: dashed;
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    outline: none;
    cursor: pointer;
}

.addOkrBtn span{
    padding-right: 8px
}


@media (max-width: 1024px){
    .okrsBoard{
        flex-direction: column;
        margin: 24px;
        border: 0;
        padding: 0;
    }

    .addOkrsTitle{
        font-size: 14px !important;
    }

    .okrContainerHeader{
        gap: 8px;
    }

    .addOkrBtn > p{
        font-size: 8px;
    }

    .addOkrBtn > span{
        margin-right: 0;
        padding-right: 4px;
    }

    .keyResultItem{
        gap: 16px;
    }

    .keyResultItemLeft{
        flex: 0 0 60%;
    }
}
