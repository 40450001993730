#root{
  overflow-x: hidden;
}

.Main {
  display: flex;
  flex-direction: row;
}
.Content {
  display: flex;
  flex-direction:column;
  width: calc(100% - 15.9rem);
  margin-left: 15.9rem;
}


@media (max-width: 1024px){
  .Content{
    width: 100%;
    margin-left: unset;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}