.team-screen-container{
    padding: 12px 52px 40px 52px;
}

.team-screen-header{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 32px;
}

.team-screen-teams{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.team-screen-teams-item{
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.team-screen-teams-item-header{
    display: flex;
    justify-content: space-between;
}

.team-screen-teams-item-header-actions{
    display: flex;
    gap: 8px;
}

.team-screen-teams-item-header input{
    font-weight: 700;
    font-size: 32px;
    letter-spacing: -0.04em;
    color: #1E1848;
    border: none !important;
    outline: none !important;
}

.team-screen-container-error{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.team-screen-peoples {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
}

.team-screen-peoples-item{
    width: 300px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;
}

.team-screen-peoples-item-image {
    width: 100%;
    border-radius: 10px 10px 0 0;
    background-color: #8993A4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.team-screen-peoples-item-content{
    padding: 16px;
}

.team-screen-peoples-item-content > p{
    font-weight: 700;
    font-size: 20px;
    color: #1E1848;
    margin: 0;
}

.team-screen-peoples-item-content > span {
    font-size: 16px;
    color: #1E1848;
    margin: 0;
}

.team-screen-peoples-item-content-actions{
    display: flex;
    margin-top: 24px;
    flex-direction: row;
    justify-content: space-between;
}

.team-screen-container-error > p {
    color: #665DAA;
    font-size: 20px;
}

.team-screen-container-button, .team-screen-header-button{
    border-color: #FBC4DD !important;
    color: #F1388D !important;
    text-transform: none !important;
}

.team-screen-container-button > svg, .team-screen-header-button > svg{
    margin-right: 8px;
}

.team-screen-container-button:hover, .team-screen-header-button:hover{
    background-color: white !important;
}

@media (max-width: 1024px){
    .team-screen-container{
        padding: 48px 24px;
    }

    .team-screen-peoples{
        gap: 24px 0; 
    }

    .team-screen-peoples-item{
        width: 100%;
    }
}

.minimized{
    display: none !important;
}